import axios from "axios";

const API = "metricas-admin";

const metricasAdmin = {
    ventas_mensuales(params){
        return axios(`${API}/ventas-mensuales`, { params });
    },
    ventas_diarias(params){
        return axios(`${API}/ventas-diarias`, { params });
    },
    calificaciones_mensuales(params){
        return axios(`${API}/calificaciones-mensuales`, { params });
    },
    ver_mas_productos(params){
        return axios(`${API}/ver-mas-productos`, { params });
    },
    metricas_informe(params){
        return axios(`${API}/metricas-informe`, { params });
    },
    crecimiento(params){
        return axios(`${API}/clientes-crecimiento`, { params });
    },
    informe(params){
        return axios(`${API}/clientes-informe`, { params });
    },
    calendario(id_tendero){
        return axios(`${API}/${id_tendero}/calendario`);
    },
    comparativos(params){
        return axios(`${API}/comparativos`, { params });
    },
    info_tenderos(params){
        return axios.post(`${API}/info-tenderos`, params);
    },
    ver_cliente(params){
        return axios(`${API}/ver-cliente`, { params });
    }
};

export default metricasAdmin;
