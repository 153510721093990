<template>
    <section
    class="pt-3 position-relative custom-scroll overflow-auto height-otro"
    >
        <timercomponent v-model="tipo_fecha" @refresh="montar()" />

        <div class="row mx-0 mt-5">
            <p class="f-18 pl-5 f-600 text-general">
                Compras realizadas entre el {{ semana }} (Últimas 12 semanas)
            </p>
            <div class="col-12">
                <chartBarras :datos="compras_realizadas" :chart="chart_diario" />
            </div>
        </div>
        <div class="row mx-0 px-5 justify-content-start">
            <div class="col-xl-auto col-lg-auto col-md col-sm col d-middle text-general f-15">
                Rango de análisis
            </div>
            <div class="col-xl-auto col-lg-auto col-md-5 col-sm-5 col-5">
                <el-select v-model="rango" placeholder="Seleccionar" size="medium" class="w-100">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm col px-0 mx-2">
                <div class="date-picker-edit">
                    <el-date-picker
                    v-model="fecha"
                    class="w-100"
                    size="medium"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="disable_fecha"
                    popper-class="date-picker-edit-popper"
                    type="daterange"
                    unlink-panels
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
            </div>
            <div class="col-auto">
                <div class="cr-pointer text-white p-1 px-3 f-18 br-12 bg-general" @click="metricas_informe">
                    Mostrar informe
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-5">
            <div class="col">
                <p class="f-18 pl-5 f-600 text-general">
                    Productos más comprados en cuanto a dinero
                </p>
            </div>
            <div class="col-auto">
                <div class="bg-light-f5 text-general2 br-12 text-center px-3">
                    <i class="icon-package-variant f-18" />
                    Stock Actual
                </div>
            </div>
            <div class="col-12">
                <div class="row mx-0 pl-4">
                    <template v-if="productos_card_total.length">
                        <div class="col">
                            <div class="row mx-0 pt-3">
                                <div v-for="(data,d) in productos_card_total" :key="d" class="col-auto text-center position-relative mt-3">
                                    <div v-if="data.stock_uso == 1" class="bg-danger br-12 text-white position-absolute" style="min-width:65px;left:30px;top:-13px;">
                                        <i class="icon-package-variant f-18" />
                                        {{ agregarSeparadoresNumero(data.stock_actual) }}
                                    </div>
                                    <el-tooltip effect="light" :content="data.nombre" placement="bottom">
                                        <img :src="data.foto_firmada" width="90px" height="90px" class="border br-12 my-1" />
                                    </el-tooltip>
                                    <p class="text-general f-15 text-center">
                                        {{ separadorNumero(data.total,2) }}
                                    </p>
                                    <p class="text-general f-18 f-600 text-center">
                                        {{ agregarSeparadoresNumero(data.porcentaje_total,1) }}%
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <!-- Sin datos -->
                    <div v-else class="col">
                        <div class="row justify-center">
                            <div class="borde text-center p-2 br-12 sin_datos">
                                <img src="/img/no-imagen/mercado.svg" alt="" />
                            </div>
                        </div>
                        <div class="row justify-center f-15 text-muted2">
                            Aun no se han registrado ventas
                        </div>
                    </div>
                    <div v-if="productos_ver_mas_total.total > 0" class="col-auto ml-auto">
                        <div class="br-12 bg-general3 d-middle-center cr-pointer" style="width:115px;height:115px;" @click="AbrirVerMasDinero">
                            <p class="mb-0 text-white">
                                Ver más <br /> <i class="icon-angle-right text-white ml-3" />
                            </p>
                        </div>
                        <p class="mb-0 text-general2 f-15 text-center">
                            {{ separadorNumero(productos_ver_mas_total.total,1) }}
                        </p>
                        <p class="mb-0 text-general f-600 f-18 text-center">
                            {{ agregarSeparadoresNumero(productos_ver_mas_total.porcentaje,1) }}%
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-5">
            <div class="col">
                <p class="f-18 pl-5 f-600 text-general">
                    Productos más comprados en cuanto a cantidad
                </p>
            </div>
            <div class="col-auto">
                <div class="bg-light-f5 text-general2 br-12 text-center px-3">
                    <i class="icon-package-variant f-18" />
                    Stock Actual
                </div>
            </div>
            <div class="col-12">
                <div class="row mx-0 pl-4">
                    <template v-if="productos_card_cantidad.length">
                        <div class="col">
                            <div class="row mx-0 pt-3">
                                <div v-for="(data,d) in productos_card_cantidad" :key="d" class="col-auto text-center position-relative mt-3">
                                    <div v-if="data.stock_uso == 1" class="bg-danger br-12 text-white position-absolute" style="min-width:65px;left:30px;top:-13px;">
                                        <i class="icon-package-variant f-18" />
                                        {{ agregarSeparadoresNumero(data.stock_actual) }}
                                    </div>
                                    <el-tooltip effect="light" :content="data.nombre" placement="bottom">
                                        <img :src="data.foto_firmada" width="90px" height="90px" class="border br-12 my-1" />
                                    </el-tooltip>
                                    <p class="text-general f-15 text-center">
                                        {{ agregarSeparadoresNumero(data.cantidad,2) }}
                                    </p>
                                    <p class="text-general f-18 f-600 text-center">
                                        {{ agregarSeparadoresNumero(data.porcentaje_cantidad,1) }}%
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <!-- Sin datos -->
                    <div v-else class="col">
                        <div class="row justify-center">
                            <div class="borde text-center p-2 br-12 sin_datos">
                                <img src="/img/no-imagen/mercado.svg" alt="" />
                            </div>
                        </div>
                        <div class="row justify-center f-15 text-muted2">
                            Aun no se han registrado ventas
                        </div>
                    </div>
                    <div v-if="productos_ver_mas_cantidad.cantidad > 0" class="col-auto ml-auto">
                        <div class="br-12 bg-general3 d-middle-center cr-pointer" style="width:115px;height:115px;" @click="AbrirVerMasCantidad">
                            <p class="mb-0 text-white">
                                Ver más <br /> <i class="icon-angle-right text-white ml-3" />
                            </p>
                        </div>
                        <p class="mb-0 text-general f-15 text-center">
                            {{ agregarSeparadoresNumero(productos_ver_mas_cantidad.cantidad,1) }}
                        </p>
                        <p class="mb-0 text-general f-600 f-18 text-center">
                            {{ agregarSeparadoresNumero(productos_ver_mas_cantidad.porcentaje,1) }}%
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-5">
            <div class="col">
                <p class="f-18 pl-5 f-600 text-general">
                    Productos más comprados por todas las tiendas
                </p>
            </div>
            <div class="col-12">
                <div class="row mx-0 pl-4">
                    <div class="col">
                        <div class="row mx-0 pt-3">
                            <div v-for="(data,d) in productos_tiendas" :key="d" class="col-auto text-center position-relative mt-4">
                                <div v-if="data.stock_uso == 1" class="bg-danger br-12 text-white position-absolute" style="min-width:65px;left:30px;top:-13px;">
                                    <i class="icon-package-variant f-18" />
                                    {{ agregarSeparadoresNumero(data.stock_actual) }}
                                </div>
                                <el-tooltip effect="light" :content="data.nombre" placement="bottom">
                                    <img :src="data.foto_firmada" width="90px" height="90px" class="border br-12 my-1" />
                                </el-tooltip>
                                <div class="bg-general3 br-12 text-white position-absolute rounded-circle f-12 d-middle-center f-600" style="width:25px;height:25px;left:47px;">
                                    {{ d + 1 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modales -->
        <ver-mas ref="VerMas" :relacion="relacion" :duracion="duracion" />
    </section>
</template>
<script>
/* Import Echarts */
import Vue from 'vue'
import moment from 'moment'
import echarts from 'echarts'
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general2')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')
import MetricasAdmin from "~/services/metricas_admin"

export default {
    components: {
        verMas: () => import('./partials/modalVerMas'),
    },
    data(){
        return{
            compras_realizadas: [
                { nombre: 'Compras Realizadas', valor: 0, color: 'bg-gr-general', formato:1 },
                { nombre: 'Ventas Realizadas', valor: 0, color: 'bg-gr-red', formato:1 }
            ],
            tipo_fecha: 1,
            semana: '',
            chart_diario: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Compras', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    }
                ],
                dataset:{
                    dimensions:['semana', 'compras','ventas'],
                    source: []
                },
                series: [
                    {
                        name: 'Compras',
                        type: 'bar',
                        itemStyle: {
                            color: colorGeneral2
                        },
                    },
                    {
                        name: 'Ventas',
                        type: 'bar',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]
            },
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            disable_fecha: true,
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 3,
            value: '',
            value1: '',
            relacion: '',
            duracion: [
                { fecha: '20 Jun.2020'},
                { fecha: '05 Nov.2020'}
            ],
            productos_card_total: [],
            productos_ver_mas_total: {
                total: 0,
                porcentaje: 0
            },
            productos_card_cantidad: [],
            productos_ver_mas_cantidad: {
                total: 0,
                porcentaje: 0
            },
            metricas_created: null,
            metricas_entregas: null,
            calendario_created: null,
            calendario_entregas: null,
            productos_tiendas: [],
        }
    },
    computed: {
        id_tienda(){
            let id_tienda = null
            if (this.$route.params.id_tienda){
                id_tienda = this.$route.params.id_tienda
            } else {
                id_tienda = this.$usuario.tienda.id
            }

            return id_tienda
        },
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.duracion[0].fecha = moment(this.fecha[0]).format('D MMM Y')
                this.duracion[1].fecha = moment(this.fecha[1]).format('D MMM Y')
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                this.duracion[0].fecha = moment(this.fecha[0]).format('D MMM Y')
                this.duracion[1].fecha = moment(this.fecha[1]).format('D MMM Y')
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.duracion[0].fecha = moment(this.fecha[0]).format('D MMM Y')
                this.duracion[1].fecha = moment(this.fecha[1]).format('D MMM Y')
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                this.duracion[0].fecha = moment(this.fecha[0]).format('D MMM Y')
                this.duracion[1].fecha = moment(this.fecha[1]).format('D MMM Y')
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.duracion[0].fecha = moment(this.fecha[0]).format('D MMM Y')
                this.duracion[1].fecha = moment(this.fecha[1]).format('D MMM Y')
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                this.duracion[0].fecha = moment(this.fecha[0]).format('D MMM Y')
                this.duracion[1].fecha = moment(this.fecha[1]).format('D MMM Y')
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.duracion[0].fecha = moment(this.fecha[0]).format('D MMM Y')
                this.duracion[1].fecha = moment(this.fecha[1]).format('D MMM Y')
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                this.duracion[0].fecha = moment(this.fecha[0]).format('D MMM Y')
                this.duracion[1].fecha = moment(this.fecha[1]).format('D MMM Y')
                break;

            default:
                break;
            }
        },
        tipo_fecha(){
            this.calcularCalendario()
            this.calcularMetricasInformes()
        },
    },
    async mounted(){
        await this.datos_calendario()
        await this.metricas_informe()
    },
    methods: {
        async montar(){
            try {
                await this.datos_calendario()
                await this.metricas_informe()
            } catch (error){

            }
        },
        AbrirVerMasDinero(){
            let params = {
                id_tendero: this.$usuario.id,
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                tipo_fecha: this.tipo_fecha,
                tipo:1,
            }
            this.relacion = 'dinero'
            this.$refs.VerMas.toggle(params)
        },
        AbrirVerMasCantidad(){
            let params = {
                id_tendero: this.$usuario.id,
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                tipo_fecha: this.tipo_fecha,
                tipo:2
            }
            this.relacion = 'cantidad'
            this.$refs.VerMas.toggle(params)
        },
        async datos_calendario(){
            try {
                const { data } = await MetricasAdmin.calendario(this.$usuario.id)
                this.$validar(data)
                this.calendario_created = data.data.pedidos_created
                this.calendario_entregas = data.data.pedidos_entregas

                this.calcularCalendario()
            } catch (e){
                this.error_catch(e)
            }
        },
        calcularCalendario(){
            try {

                let datos = this.tipo_fecha == 1 ? this.calendario_entregas : this.calendario_created

                this.chart_diario.dataset.source = datos.pedidos_x_semana
                this.compras_realizadas[0].valor = datos.compras_totales
                this.compras_realizadas[1].valor = datos.ventas_totales
                this.semana = datos.rango
            } catch (error){

            }
        },
        async metricas_informe(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_tendero: this.$usuario.id
                }
                const { data } = await MetricasAdmin.metricas_informe(params)

                this.metricas_created = data.data.pedidos_created
                this.metricas_entregas = data.data.pedidos_entregas

                this.calcularMetricasInformes()

                this.notificacion('Mensaje','Informe Actualizado correctamente','success')
            } catch (error){
                this.error_catch(error)
            }
        },
        calcularMetricasInformes(){
            try {

                let datos = this.tipo_fecha == 1 ? this.metricas_entregas : this.metricas_created

                datos.productos_card_total.map(p=>{
                    p.stock = p.stock_uso == 1 ? (p.stock_minimo >= Number(p.stock_actual)) : false
                })
                this.productos_card_total = datos.productos_card_total
                this.productos_ver_mas_total = datos.productos_ver_mas_total

                datos.productos_card_cantidad.map(p=>{
                    p.stock = p.stock_uso == 1 ? (p.stock_minimo >= Number(p.stock_actual)) : false
                })
                datos.productos_tiendas.map(p=>{
                    p.stock = p.stock_uso == 1 ? (p.stock_minimo >= Number(p.stock_actual)) : false
                })

                this.productos_card_cantidad = datos.productos_card_cantidad
                this.productos_ver_mas_cantidad = datos.productos_ver_mas_cantidad
                this.productos_tiendas = datos.productos_tiendas
            } catch (error){
                console.log(error);
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.blue-light{
    color:#0892DD;
}
.br-12{
    border-radius: 12px;
}
.ver-mas{
    height: 90px;
    width: 90px;
}
.height-otro{
    height: calc(100vh - 131px);
}
</style>
